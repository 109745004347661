






























import {vxm} from "@/store";
import {Component, Vue} from 'vue-property-decorator';
import DocumentRow from "@/views/widgets/DocumentRow.vue";

import WaitModal from "@/views/widgets/modals/WaitModal.vue";
import YesNoModal from "@shared_vue/components/YesNoModal.vue";
import {Configuration, DocumentDTO, FileApiFactory, ProfileApiFactory} from "@shared_vue/openapi/myvaultapi";





@Component({
  components: {DocumentRow, WaitModal, YesNoModal},
})

export default class DocumentList extends Vue {
  private ui = vxm.ui;
  private fileApi = FileApiFactory(<Configuration>{basePath:process.env.VUE_APP_MYVAULT_API_URL},process.env.VUE_APP_MYVAULT_API_URL);
  private documentList : DocumentDTO[] = []
  private showWait: boolean = false;
  private showYesNoDelete: boolean = false;
  private tempId=0;
  private newName : string = ''
  private newCat: any = 0;
  private showNewDocument1: boolean = false;
  private showNewDocument2: boolean = false;
  private newDescription: string = '';
  private showEditModal: boolean = false;
  private tempDTO = <DocumentDTO>{};
  private catoptions = [
    {value:1, label:'Category 1'}
  ]

  private submitNew(){
    console.log('saving...')
    //add waiting modal


  }

  private submitUpdate(){

  }

  get CatName(){
    return this.catoptions.find(a=>a.value==this.newCat)?.label??''
  }

  private downloadDocument(id:number){
    this.fileApi.fileGetFileLinkById(id).then((link)=>{
      // let a = document.createElement('a');
      // a.href = link.data;
      // a.target="_blank";
      // a.download = "testfile.txt";//link.data.substr(link.data.lastIndexOf('/') + 1);
      // console.log(a.outerHTML)
      // document.body.appendChild(a);
      // a.click();
      // document.body.removeChild(a);

      window.open(link.data, '_blank');
    })
  }

  private next1(){
    this.showNewDocument1=false;
    this.showNewDocument2=true;
  }

  private newDocument(){
    this.showNewDocument1=true;
  }
  private deleteDocument(id:number){
    alert('not enabled')
  }

  private deleteConfirm(){
    //go via API

  }

  private cancel(){
    this.showNewDocument1=false;
    this.showNewDocument2=false;
    this.newDescription='';
    this.newName='';
    this.newCat=null;
  }

  private fetchAll(){
    this.showWait=true;
    this.fileApi.fileListFiles().then(resp => {
      console.log(resp.data);
      //add version stuff here :-O
      this.documentList=resp.data;
    })
        .catch(err => {
          // Handle Error Here
          console.error(err);
        }).finally(()=>{
      this.showWait=false;
    });
  }
  mounted(){
    this.fetchAll();
  }

}
